.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.chakra-modal__content {
  --chakra-sizes-xl: 70vw;
}

.chakra-modal__body {
  display: flex;
}

.modal-canvas {
  width: 100%;
  height: auto;
}

.logo {
  width: 202px;
  height: 71px;
  margin: 0 auto 1rem;
}

.logo-modal {
  width: 202px;
  height: 71px;
  margin: 0 auto 1rem;
}

@media screen and (max-width: 767px) {
  .logo {
    display: none;
  }
  .logo-modal {
  }
  .chakra-modal__body {
    display: block;
  }
  .chakra-modal__content {
    --chakra-sizes-xl: 90vw;
  }
}